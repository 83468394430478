/* eslint-disable max-len */
import Vue from 'vue';
import NewBooking from '@/views/Admin/NewBooking/NewBooking';

import './Bookings.scss';
import Footer from '@/components/Shared/Footer/Footer';
import ClientNav from '@/components/Clients/ClientNav';

const Bookings = Vue.extend({
  render() {
    return (
      <div class="public-booking">
        <ClientNav />

        <div class="public-booking-container">

          <div class="public-booking__image">
            <img src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663237/Teefal%20Studios/section-1.png' alt="Booking image" />
          </div>

          <div>
            <h1>Create a new booking</h1>

            <p style={{
              maxWidth: '480px',
            }}>
              We have a limited number of sessions that quickly fill up each month and can only accommodate on a first come basis.  You can reserve a session date and time with a deposit below. Your deposit is deductible from the total cost of the session listed below and we will send you an invoice for the remainder. Please make sure to read our terms and conditions.
              </p>

            <p>
              <strong>JUST TO SAY THANK YOU</strong>
              <ul>
                <li>5% off for returning clients</li>
                <li>Get N5,000 towards a session every time you refer a client</li>
              </ul>
            </p>

            <p>
              Our studio is located in Lekki County Homes, Ikota Lagos.
              <br />
            </p>

            <NewBooking />
          </div>
        </div>

        <Footer />
      </div>
    );
  },
});

export default Bookings;
